<script setup lang="ts">
import type { ISbStory, ISbStoryData } from 'storyblok-js-client';

interface StoryblokResponse {
    data: {
        stories: ISbStoryData[];
    };
}

const i18n = useI18n();
const route = useRoute();

const currentMarket = computed(() => route.query.market as string | undefined);

interface Blok {
    count: number;
    title: string;
    featuredArticles: string[];
    tags: string;
    excludeIds?: string[];
}
const props = withDefaults(
    defineProps<{
        blok: Blok;
    }>(),
    {
        blok: () => ({
            count: 3,
            title: '',
            featuredArticles: [],
            tags: '',
            excludeIds: [], // Default to empty array
        }),
    },
);

const storyblokApi = useStoryblokApi();

// Get featured articles only if there are UUIDs to fetch
let featuredArticles: StoryblokResponse = { data: { stories: [] } };
if (props.blok.featuredArticles.length > 0) {
    featuredArticles = await storyblokApi.getStories({
        version: route.query._storyblok ? 'draft' : 'published',
        resolve_links: 'url',
        by_uuids: props.blok.featuredArticles.join(','),
        language: i18n.locale.value || 'en',
    });
}

// Get tagged articles with AND logic
let taggedArticlesResult: StoryblokResponse = { data: { stories: [] } };

const tags = props.blok.tags
    .split(/[\n\s,]+/) // Split by newlines, spaces, or commas
    .map((tag) => tag.trim())
    .filter((tag) => tag); // Remove empty tags

if (tags.length > 0) {
    // First get articles for the first tag
    taggedArticlesResult = await storyblokApi.getStories({
        version: route.query._storyblok ? 'draft' : 'published',
        resolve_links: 'url',
        language: i18n.locale.value || 'en',
        with_tag: tags[0],
    });

    let filteredStories = taggedArticlesResult.data.stories;

    // For each additional tag, filter the results to only include stories with that tag too
    for (let i = 1; i < tags.length; i++) {
        const tagResult = await storyblokApi.getStories({
            version: route.query._storyblok ? 'draft' : 'published',
            resolve_links: 'url',
            language: i18n.locale.value || 'en',
            with_tag: tags[i],
        });

        // Create a map of story IDs from this tag's results for efficient lookup
        const tagStoryIds = new Set(tagResult.data.stories.map((story) => story.id));

        // Keep only stories that also have this tag
        filteredStories = filteredStories.filter((story) => tagStoryIds.has(story.id));
    }

    taggedArticlesResult.data.stories = filteredStories;
}

const dateComparator = (a: ISbStoryData, b: ISbStoryData) => {
    return (
        new Date(
            b.sort_by_date || b.first_published_at || b.published_at || b.created_at,
        ).getTime() -
        new Date(b.sort_by_date || b.first_published_at || a.published_at || a.created_at).getTime()
    );
};

const articles = computed(() => {
    let featuredArticleStories = featuredArticles.data.stories;
    let taggedArticleStories = taggedArticlesResult.data.stories;

    featuredArticleStories = featuredArticleStories.sort(dateComparator);
    taggedArticleStories = taggedArticleStories.sort(dateComparator);

    let stories = featuredArticleStories.concat(taggedArticleStories);
    // Remove duplicates
    stories = [...new Map(stories.map((item) => [item.id, item])).values()];

    // Filter stories based on visibleIn and market query param
    if (currentMarket.value) {
        stories = stories.filter((story) => {
            const visibleIn = story.content?.visibleIn;
            // If visibleIn is empty or undefined, show the story
            // If visibleIn has values, only show if it includes current market
            return (
                !visibleIn ||
                !Array.isArray(visibleIn) ||
                visibleIn.length === 0 ||
                visibleIn.includes(currentMarket.value)
            );
        });
    }

    // Filter out excluded articles
    if (props.blok.excludeIds && props.blok.excludeIds.length > 0) {
        stories = stories.filter(
            (story) => !props.blok.excludeIds?.includes(story?.content?._uid ?? ''),
        );
    }

    return stories.slice(0, props.blok.count);
});

function blogUrl(article: any) {
    return { path: `/${article.full_slug}`, query: route.query };
}
</script>

<template>
    <section aria-label="Featured articles" class="py-8 lg:py-12 bg-gray-50 dark:bg-midnight-blue">
        <div class="px-4 mx-auto max-w-screen-xl text-center">
            <h2 v-if="blok.title" class="mb-12 text-gray-900 dark:text-white">
                {{ blok.title }}
            </h2>
            <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
                <article v-for="article in articles">
                    <NuxtLink :to="blogUrl(article)">
                        <div
                            class="p-4 h-full bg-white rounded-lg shadow-xl text-left dark:bg-midnight-blue-dark dark:border dark:border-deep-blue hover:scale-105 duration-300 motion-reduce:transition-none motion-reduce:hover:transform-none"
                        >
                            <img
                                :src="article.content.image?.filename"
                                class="mb-5 rounded-lg overflow-hidden"
                                alt="Image 1"
                            />
                            <h2
                                class="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white"
                            >
                                {{ article.content.title }}
                            </h2>
                            <p class="italic text-sm text-gray-500 dark:text-gray-400">
                                {{
                                    article.published_at ? $t('blog.published') : $t('blog.created')
                                }}
                                {{
                                    $d(
                                        new Date(
                                            article.first_published_at ||
                                                article.published_at ||
                                                article.created_at,
                                        ),
                                        {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                        },
                                    )
                                }}
                            </p>
                            <p class="mb-4 mt-4 text-gray-500 dark:text-gray-400">
                                {{ article.content.teaser }}
                            </p>
                        </div>
                    </NuxtLink>
                </article>
            </div>
        </div>
    </section>
</template>
